<template>
    <div class="right_main">
        <!-- 页面标题  按钮部分    上传组件部分-->
        <div>
            <div class="top_btn">
                <!-- 页面标题部分 -->
                <div class="page_name">
                    <div class="sig">
                        <i class="el-icon-arrow-left" @click="handle_goback"></i>
                    </div>
                    <div class="sig_name">导入</div>
                </div>
                <div class="btn_cl"></div>
            </div>
            <!-- 导入文件部分 -->
            <div class="from_main">
                <el-upload class="upload-demo" action="https://customer.ztxinde.com/customer_import_show"
                    accept=".xlsx,.xls" name="file" :headers="myHeaders" :auto-upload="true" ref='upload'
                    :data="file_load" :before-upload="handleBefore" :on-success="handleSuccess" :file-list="fileList">
                    <el-button class="btn">导入</el-button>
                </el-upload>
                <!-- <div class="btn_main">
                    <el-button class="btn_rest" @click="ResterFn">取消</el-button>
                    <el-button class="btn_submit" @click="SubmitFn">上传</el-button>
                </div> -->
                <div v-if="tip_show" style="color:red;margin-top:70px;font-size:18px;font-weight:600">文件部分内容有误！请修改后重新上传！
                </div>
                <div class="btn_main">
                    <el-button class="btn_rest" @click="ResterFn">取消</el-button>
                    <el-button class="btn_submit" @click="SubmitLoad">提交已导入</el-button>
                </div>
            </div>
        </div>
        <!-- 页面标题  按钮部分    错误信息部分-->
        <!-- <div v-if="error_show">
            <div class="top_btn">
                <div class="page_name">
                    <div class="sig"></div>
                    <div class="sig_name">错误信息</div>
                </div>
                <div class="btn_cl"></div>
            </div>
            <div class="from_main">
                <div class="sdf" v-if="succ_tip">暂无导入错误信息</div>
                <div class="ads" v-else>
                    <div class="table_list">
                        <div class="err_tip">{{name_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_name" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{sales_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_sales" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{phone_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_phone" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{area_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_area" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{proving_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_proving" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{safe_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_safe" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{car_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_car" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{frame_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_frame" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{first_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_first" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{start_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_start" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{end_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_end" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{day_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_day" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{mark_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_mark" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{ext1_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_ext1" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{ext2_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_ext2" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{ext3_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_ext3" :key="index">{{item.msg}}</div>
                    </div>
                </div>
                <div class="btn_main">
                    <el-button class="btn_rest" @click="loadFn">重新导入</el-button>
                    <el-button class="btn_submit" @click="SubmitLoad">提交已导入</el-button>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { postCustomerApi } from '@/api'
export default {
    data () {
        return {
            // token
            myHeaders: {
                'token': ''
            },
            // 有没有错误信息
            has_err: '',
            // 上传成功的数据
            success_arr: [],
            // 有下级的id
            lower_id: '',
            // 登录用户id
            user_id: '',
            // 又没有下级
            lower: '',
            file_load: {
                auth_id: '',
                has_lower: '',
                lower_id: '',
            },
            fileList: [],
            // 提示语显示
            tip_show: false,
            // 上传显示
            upload_show: true,
            // 错误信息显示
            error_show: false,
            // 成功与错误信息隐藏显示
            succ_tip: true,
            // 姓名错误  提示消息
            err_name: [],
            name_tip: '',
            // 业务员错误 提示消息
            err_sales: [],
            sales_tip: '',
            // 手机号错误 提示消息
            err_phone: [],
            phone_tip: '',
            // 地区错误 提示消息
            err_area: [],
            area_tip: '',
            // 验证字段错误  提示消息
            err_proving: [],
            proving_tip: '',
            // 保险名称错误 提示消息
            err_safe: [],
            safe_tip: '',
            // 车牌号错误 提示消息
            err_car: [],
            car_tip: '',
            // 车架号错误 提示消息
            err_frame: [],
            frame_tip: '',
            // 初登日期错误  提示消息
            err_first: [],
            first_tip: '',
            // 起保日期错误 提示消息
            err_start: [],
            start_tip: '',
            // 终保日期错误 提示消息
            err_end: [],
            end_tip: '',
            // 到期设置错误 提示消息
            err_day: [],
            day_tip: '',
            // 备注错误  提示消息
            err_mark: [],
            mark_tip: '',
            // 预留字段1错误 提示消息
            err_ext1: [],
            ext1_tip: '',
            // 预留字段2错误 提示消息
            err_ext2: [],
            ext2_tip: '',
            // 预留字段3错误 提示消息
            err_ext3: [],
            ext3_tip: '',
        }
    },
    mounted () {
        this.lower_id = this.$route.query.lowerId;
        this.user_id = sessionStorage.getItem('id');
        this.lower = sessionStorage.getItem('lower');
    },
    methods: {
        // 上传文件前
        handleBefore (file) {
            this.myHeaders.token = sessionStorage.getItem('Token')
            this.file_load.auth_id = this.user_id;
            this.file_load.has_lower = this.lower;
            this.file_load.lower_id = this.lower_id;
        },
        // 上传时
        handleprog (event, file, fileList) {
            // this.fileList.push(file);
            this.tip_show = false;
            // console.log(event)
            // if (event.percent != 100) {
            //     this.fileList = fileList;
            // } else {
            //     this.fileList = [];
            // }
        },
        // 上传文件成功
        handleSuccess (res, file, fileList) {
            // console.log(res)
            if (res.status.code == 200) {
                this.$message.success('文件上传成功');
                this.has_err = res.has_err;
                if (this.has_err == 0) {
                    this.tip_show = false;
                    this.success_arr = res.data;   //上传成功的数据
                } else if (this.has_err == 1) {
                    this.tip_show = true;
                };
                // console.log(fileList)
                this.fileList = [];
                this.fileList.push(file);
                // this.upload_show = false;
                // this.error_show = true;
                // if (this.has_err == 0) {
                //     this.succ_tip = true;
                // } else {
                //     this.succ_tip = false;
                //     this.err_name = res.errors.name.data;
                //     this.name_tip = '姓名错误原因为:' + res.errors.name.msg;
                //     this.err_sales = res.errors.servicer.data;
                //     this.sales_tip = '业务员错误原因为:' + res.errors.servicer.msg;
                //     this.err_phone = res.errors.phone.data;
                //     this.phone_tip = '手机号错误原因为:' + res.errors.phone.msg;
                //     this.err_area = res.errors.area.data;
                //     this.area_tip = '地区错误原因为:' + res.errors.area.msg;
                //     this.err_proving = res.errors.verify_field.data;
                //     this.proving_tip = '验证字段错误原因为:' + res.errors.verify_field.msg;
                //     this.err_safe = res.errors.insure.data;
                //     this.safe_tip = '保险名称错误原因为:' + res.errors.insure.msg;
                //     this.err_car = res.errors.car_number.data;
                //     this.car_tip = '车牌号错误原因为:' + res.errors.car_number.msg;
                //     this.err_frame = res.errors.frame_number.data;
                //     this.frame_tip = '车架号错误原因为:' + res.errors.frame_number.msg;
                //     this.err_first = res.errors.first_date.data;
                //     this.first_tip = '初登日期错误原因为:' + res.errors.first_date.msg;
                //     this.err_start = res.errors.start_date.data;
                //     this.start_tip = '起保日期错误原因为:' + res.errors.start_date.msg;
                //     this.err_end = res.errors.end_date.data;
                //     this.end_tip = '终保日期错误原因为:' + res.errors.end_date.msg;
                //     this.err_day = res.errors.remind_day.data;
                //     this.day_tip = '到期提醒错误原因为:' + res.errors.remind_day.msg;
                //     this.err_mark = res.errors.remark.data;
                //     this.mark_tip = '备注错误原因为:' + res.errors.remark.msg;
                //     this.err_ext1 = res.errors.ext1.data;
                //     this.ext1_tip = '预留字段1错误原因为:' + res.errors.ext1.msg;
                //     this.err_ext2 = res.errors.ext2.data;
                //     this.ext2_tip = '预留字段2错误原因为:' + res.errors.ext2.msg;
                //     this.err_ext3 = res.errors.ext3.data;
                //     this.ext3_tip = '预留字段3错误原因为:' + res.errors.ext3.msg;
                // }
            } else if (res.status.code == 201) {
                this.$message.error(res.status.msg);
                this.upload_show = true;
                this.error_show = false;
            } else if (res.status.code == 202) {
                this.$message.error('登录过期，请重新登录！');
                this.$router.push({ path: '/login' });
            }
        },
        // 点击取消按钮
        ResterFn () {
            this.$router.push({ path: '/custom' })
        },
        // 点击上传按钮
        SubmitFn () {
            this.$refs.upload.submit();
        },
        // 点击重新导入
        loadFn () {
            this.upload_show = true;
            this.error_show = false;
        },
        // 点击返回按钮返回
        handle_goback () {
            this.$router.push({ path: '/custom' })
        },
        // 点击提交已导入
        SubmitLoad () {
            postCustomerApi({
                data: this.success_arr,
                auth_id: this.user_id,
                has_lower: this.lower,
                lower_id: this.lower_id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.$message.success('导入成功');
                    this.$router.push({ path: '/custom' });
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' });
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.right_main {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #ffffff;
    position: relative;
    //  页面标题  按钮部分
    .top_btn {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding: 24px;
        border-bottom: 1px solid #e8e9f1;
        // 页面标题部分
        .page_name {
            display: flex;
            align-items: center;
            .sig {
                margin-right: 6px;
                i {
                    font-size: 24px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
            .sig_name {
                font-size: 16px;
                font-weight: 600;
                color: #000000;
            }
        }
        .btn_cl {
            width: 10px;
        }
    }
    // 输入框部分
    .from_main {
        padding: 24px;
        height: 540px;
        // 下载组件
        .upload-demo {
            width: 96%;
            height: 248px;
            border: 1px dashed #000000;
            position: relative;
            padding: 24px;
            .btn {
                width: 126px;
                height: 52px;
                border: none;
                background: #6a8be8;
                color: #ffffff;
                font-size: 18px;
                font-weight: 400;
                position: absolute;
                bottom: 10%;
                left: 45%;
            }
        }
        // 错误信息展示
        .sdf {
            text-align: center;
            padding: 20px;
            font-size: 18px;
            font-weight: 400;
            color: #20252b;
            text-align: left;
        }
        .ads {
            text-align: left;
            .table_list {
                text-align: left;
                padding: 0 16px;
                margin-bottom: 16px;
                .err_tip {
                    font-size: 20px;
                    font-weight: 600;
                    color: #20252b;
                    margin-bottom: 10px;
                }
                .err_data {
                    font-size: 18px;
                    font-weight: 400;
                    color: #20252b;
                }
            }
        }
        // 提交 重置按钮部分
        .btn_main {
            // margin-top: 100px;
            text-align: left;
            // padding: 0 20px 32px;
            position: absolute;
            left: 22px;
            bottom: 24px;
            .el-button {
                width: 160px;
                height: 40px;
                border-radius: 6px;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
            }
            .btn_submit {
                border: none;
                background: #6a8be8;
                margin-left: 8px;
                color: #ffffff;
            }
            .btn_rest {
                border: 1px solid #d9d9d9;
                color: #20252b;
                background: #ffffff;
            }
        }
    }
}
</style>